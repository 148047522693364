import _objectSpread from "/Users/yuqingying/Documents/ranJing/project/frontend/school-sport-test-h5-student/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { defineComponent, reactive, toRefs, onUnmounted } from 'vue';
import { mybus, MittTypes } from '@/hooks';
export default defineComponent({
  props: {},
  components: {},
  setup: function setup(props) {
    var dataMap = reactive({
      show: false
    });

    function onClose() {
      dataMap.show = false;
    }

    mybus.on(MittTypes.SHOW_CAMERA_TIPS, function (result) {
      dataMap.show = true;
    });
    onUnmounted(function () {
      mybus.off(MittTypes.SHOW_CAMERA_TIPS);
    });
    var refData = toRefs(dataMap);
    return _objectSpread(_objectSpread({}, refData), {}, {
      onClose: onClose
    });
  }
});