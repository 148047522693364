import _objectSpread from "/Users/yuqingying/Documents/ranJing/project/frontend/school-sport-test-h5-student/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { defineComponent, reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { navBar } from '@/constant/constants';
export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    }
  },
  components: {},
  emits: ['handleGoBack'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var router = useRouter();
    var dataMap = reactive({
      isWhite: false,
      isBlack: false,
      leftImg: '',
      rightIcon: ''
    });

    if (props.color == navBar.WHITE) {
      dataMap.isWhite = true;
      dataMap.isBlack = false;
      dataMap.leftImg = require('@/assets/img/student/back.png');
    } else {
      dataMap.isWhite = false;
      dataMap.isBlack = true;
      dataMap.leftImg = require('@/assets/img/student/backBlack.png');
    }

    var onClickLeft = function onClickLeft() {
      emit('handleGoBack', 'true');
    };

    var refData = toRefs(dataMap);
    return _objectSpread(_objectSpread({}, refData), {}, {
      onClickLeft: onClickLeft
    });
  }
});