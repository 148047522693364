enum selectType{
  GRADE = 1,
  CLAZZ = 2,
  SEX = 3
}

enum navBar{
  WHITE = '1',
  BLACK = '2'
}

enum sexE{
  BOY = '1',
  GIRL = '2'
}
export {selectType,navBar,sexE}