import _objectSpread from "/Users/yuqingying/Documents/ranJing/project/frontend/school-sport-test-h5-student/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
import { defineComponent, reactive, toRefs, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { navBar } from '@/constant/constants';
import cVanNavBar from '@/components/cVanNavBar.vue';
import cCameraTips from '@/components/cCameraTips.vue';
import cStep1 from './components/cameraFace/step1.vue';
import cStep2 from './components/cameraFace/step2.vue';
import axios from 'axios';
import { mybus, MittTypes } from '@/hooks';
export default defineComponent({
  props: {},
  components: {
    cVanNavBar: cVanNavBar,
    cCameraTips: cCameraTips,
    cStep1: cStep1,
    cStep2: cStep2
  },
  setup: function setup() {
    var router = useRouter();
    var route = useRoute();
    var dataMap = reactive({
      ruleForm: {
        id: 0,
        name: '',
        number: '',
        token: ''
      },
      navBar: {
        title: '',
        color: navBar.BLACK
      },
      hasAvatar: false,
      avatarUrl: '',
      step: {
        step1: true,
        step2: false,
        step3: false
      },
      hasError: false,
      errorMessage: '',
      subBtnDisabled: true,
      cameraPhotoUrl: ''
    });
    dataMap.ruleForm.name = route.params.name;
    dataMap.ruleForm.number = route.params.number;
    dataMap.ruleForm.token = route.params.token;

    function handleGoBack() {
      router.push('/studentDetail/' + dataMap.ruleForm.token + '/' + dataMap.ruleForm.number + '/' + dataMap.ruleForm.name);
    }

    function handleShowTips() {
      mybus.emit(MittTypes.SHOW_CAMERA_TIPS, true);
    }

    function handleGotoStep2() {
      dataMap.step.step1 = false;
      dataMap.step.step2 = true;
      dataMap.step.step3 = false;
      dataMap.subBtnDisabled = false;
    }

    function handleCameraSnapshot() {
      mybus.emit(MittTypes.CAMERA_SNAPSHOT, true);
    }

    mybus.on(MittTypes.CAMERA_PHOTO, function (result) {
      dataMap.step.step1 = false;
      dataMap.step.step2 = false;
      dataMap.step.step3 = true;
      dataMap.cameraPhotoUrl = result.cameraPhotoBase64;
      postData(result);
    });

    function postData(result) {
      var form = new FormData();
      form.append('avatar', dataURLtoFile(result.cameraPhotoBase64, 'camera.png'));
      form.append('student_number', dataMap.ruleForm.number);
      form.append('token', dataMap.ruleForm.token);
      axios.defaults.baseURL = localStorage.getItem('httpUrl');
      var url = "/api/student/update_avatar";
      axios.defaults.headers['Content-Type'] = 'multipart/form-data';
      axios.defaults.timeout = 15000;
      axios.post(url, form).then(function (res) {
        if (res.data.code == 200) {
          router.push('/studentDetail/' + dataMap.ruleForm.token + '/' + dataMap.ruleForm.number + '/' + dataMap.ruleForm.name);
        } else {
          dataMap.subBtnDisabled = true;
          dataMap.hasError = true;
          dataMap.errorMessage = res.data.msg;
        }
      });
    } // base64转文件


    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(',');
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {
        type: mime
      });
    }

    function handleReCamera() {
      dataMap.step.step1 = false;
      dataMap.step.step2 = true;
      dataMap.step.step3 = false;
      dataMap.subBtnDisabled = false;
      dataMap.hasError = false;
      dataMap.errorMessage = '';
      mybus.emit(MittTypes.RE_CAMERA_PHOTO, true);
    }

    onUnmounted(function () {
      mybus.off(MittTypes.CAMERA_PHOTO);
    });
    var refData = toRefs(dataMap);
    return _objectSpread(_objectSpread({}, refData), {}, {
      handleGoBack: handleGoBack,
      handleShowTips: handleShowTips,
      handleGotoStep2: handleGotoStep2,
      handleCameraSnapshot: handleCameraSnapshot,
      handleReCamera: handleReCamera
    });
  }
});